<template>
  <main>
    <trac-back-button>Open Business Account</trac-back-button>
    <div class="dashboard shadow-md m-8 px-4 py-10 gap-20">
      <div class="flex justify-center items-center flex-col px-6">
        <p class="mb-8 font-bold">
          What do you get when you create a business
          <span class="text-primaryBlue block">Traction Account?</span>
        </p>
        <trac-button>Get started</trac-button>
      </div>
      <div class="grid grid-cols-3 gap-6">
        <div class="bg-accentBlue bg-opacity-25 flex flex-col justify-between items-start p-4 w-11/12">
          <img src="../../assets/svg/edit.svg" alt="" />
          <p class="text-xs mt-4">Access to businnes loans</p>
        </div>
        <div class="bg-accentBlue bg-opacity-25 flex flex-col justify-between items-start p-4 w-11/12">
          <img src="../../assets/svg/edit.svg" alt="" />
          <p class="text-xs mt-4">Traction loyalty Rewards</p>
        </div>
        <div class="bg-accentBlue bg-opacity-25 flex flex-col justify-between items-start p-4 w-11/12">
          <img src="../../assets/svg/edit.svg" alt="" />
          <p class="text-xs mt-4">Traction loyalty Rewards</p>
        </div>
        <div class="bg-accentBlue bg-opacity-25 flex flex-col justify-between items-start p-4 w-11/12">
          <img src="../../assets/svg/edit.svg" alt="" />
          <p class="text-xs mt-4">Quick bulk payouts</p>
        </div>
        <div class="bg-accentBlue bg-opacity-25 flex flex-col justify-between items-start p-4 w-11/12">
          <img src="../../assets/svg/edit.svg" alt="" />
          <p class="text-xs mt-4">International payments</p>
        </div>
        <div class="bg-accentBlue bg-opacity-25 flex flex-col justify-between items-start p-4 w-11/12">
          <img src="../../assets/svg/edit.svg" alt="" />
          <p class="text-xs mt-4"> Simplified Payments &amp; transactions</p>
        </div>
        <div class="bg-accentBlue bg-opacity-25 flex flex-col justify-between items-start p-4 w-11/12">
          <img src="../../assets/svg/edit.svg" alt="" />
          <p class="text-xs mt-4">Exclusive Founders Card </p>
        </div>
        <div class="bg-accentBlue bg-opacity-25 flex flex-col justify-between items-start p-4 w-11/12">
          <img src="../../assets/svg/edit.svg" alt="" />
          <p class="text-xs mt-4">Virtual expense accounts</p>
        </div>
        <div class="bg-accentBlue bg-opacity-25 flex flex-col justify-between items-start p-4 w-11/12">
          <img src="../../assets/svg/edit.svg" alt="" />
          <p class="text-xs mt-4">Traction loyalty Rewards</p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.dashboard {
  display: grid;
  grid-template-columns: 400px 1fr;
}
</style>
